import * as s from './ArBrokerFeatures.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import React, { useEffect, useState } from 'react'
import { TarBrokerFeaturesProps } from './ArBrokerFeatures.types'

import BrokerSwitch from 'src/assets/images/ar-broker-switching.png'
import BrokerUI from 'src/assets/images/ar-broker-user-interface.png'
import BrokerSwitchWebp from 'src/assets/images/ar-broker-switching.webp'
import BrokerUIWebp from 'src/assets/images/ar-broker-user-interface.webp'
import TemplatingSVG from 'src/assets/svg/broker-templating.svg'
import AdminSVG from 'src/assets/svg/broker-admin.svg'
import RenewalsSVG from 'src/assets/svg/broker-renewals.svg'
import ClauseSVG from 'src/assets/svg/broker-clause.svg'
import ConditionalSVG from 'src/assets/svg/broker-conditional.svg'
import SecureSVG from 'src/assets/svg/broker-secure.svg'
import VariantsSVG from 'src/assets/svg/broker-variants.svg'
import MasterSVG from 'src/assets/svg/broker-m-templates.svg'
import { ArBrokerCustomerLove } from 'src/components/ArBrokerCustomerLove'
import { isWebpSupported } from 'src/utils/isWebpSupported'

const items = [
  { title: 'Super admins', description: 'Control user permissions and master templates.', icon: <AdminSVG /> },
  { title: 'Master templates', description: 'Cascade variables from your master templates.', icon: <MasterSVG /> },
  { title: 'User templates', description: 'Admins manage templates within teams.', icon: <TemplatingSVG /> },
  { title: 'Clause library', description: 'Create and upload clauses easily.', icon: <ClauseSVG /> },
  { title: 'Variants', description: 'Customise templates easily with variants.', icon: <VariantsSVG /> },
  { title: 'Conditional logic', description: 'For effortless dynamic contracts.', icon: <ConditionalSVG /> },
  { title: 'Secure by default', description: 'ISO 27001 certified for security by default.', icon: <SecureSVG /> },
  { title: 'Renewals', description: 'Improved endorsement filters and consolidation.', icon: <RenewalsSVG /> },
]

export const ArBrokerFeatures: React.FC<TarBrokerFeaturesProps> = () => {
  const [brokerSwitchUrl, setBrokerSwitchUrl] = useState<string>()
  const [brokerUIUrl, setBrokerUIUrl] = useState<string>()

  useEffect(() => {
    setBrokerSwitchUrl(isWebpSupported() ? BrokerSwitchWebp : BrokerSwitch)
    setBrokerUIUrl(isWebpSupported() ? BrokerUIWebp : BrokerUI)
  }, [])
  return (
    <>
      <div className="w-full ar-gradient-light">
        <ArContainer className={s.container}>
          <div className="flex flex-col lg:flex-row w-full gap-20 items-center">
            <div className="flex flex-col max-w-xl">
              <h2>Switching made simple</h2>
              <div className="flex flex-col gap-3 my-auto">
                <p>
                  Artificial’s Contract Builder lets you migrate from old contracts to the new MRCv3 format with minimal
                  fuss.
                </p>
                <p>
                  Our powerful extraction tool reduces data entry and manual rekeying, efficiently captures and
                  structures valuable PDF data, and flows seamlessly and securely into digital contracts and placement
                  platforms.
                </p>
              </div>
              <ul className="pt-4">
                <li>Extract from PDF</li>
                <li>Automatically detect & insert Conditions wordings</li>
                <li>Structure data into new MRCv3 format</li>
              </ul>
            </div>
            <div className="max-w-lg lg:max-w-none lg:w-full -my-6 md:-my-0">
              <img src={brokerSwitchUrl} loading="lazy" alt="Screenshot of artificial's broker tool user interface" />
            </div>
          </div>
        </ArContainer>
      </div>
      <div className="w-full">
        <ArContainer className={`${s.container} flex justify-between w-full`}>
          <div className="max-w-lg lg:max-w-xl">
            <img src={brokerUIUrl} loading="lazy" alt="Screenshot of artificial's broker tool user interface" />
          </div>
          <div className="flex flex-col w-full  items-center my-6">
            <div className="flex flex-col max-w-xl">
              <h2>Powerful document editor</h2>
              <div className="flex flex-col gap-3 my-auto">
                <p>
                  Create digital contracts while maintaining familiar MS Word-style formatting functionality, ensuring
                  an easy transition from your current in-house workflow.
                </p>
              </div>
              <ul className="pt-4">
                <li>Create MRCv3 contract templates</li>
                <li>Tailor to your brand</li>
                <li>Section grain handling</li>
              </ul>
            </div>
          </div>
        </ArContainer>
      </div>
      <div className="w-full">
        <ArContainer className={`${s.admincontainer} `}>
          <div className="flex flex-col gap-8 items-center justify-center w-full">
            <h2>Enhanced functionality</h2>
            <div className="grid lg:grid-cols-4 gap-12 w-full">
              {items.map((item, index) => (
                <div key={index} className="ar-gradient-light w-full p-10 rounded-2xl">
                  <div className="mt-4">{item.icon}</div>
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </ArContainer>
      </div>
      <ArBrokerCustomerLove />
    </>
  )
}
