import * as s from './ArBrokerCustomerLove.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import React, { useEffect, useState } from 'react'
import LocktonLogo from 'src/assets/images/lockton-logo.png'

import { TArBrokerCustomerLoveProps } from './ArBrokerCustomerLove.types'
import { ArRichCtaWithBg } from 'src/components/ArRichCtaWithBg'
import { METRICS } from 'src/config/metrics'
import HomepageFooterCtaBg from 'src/assets/images/HomepageFooterCtaBg.jpg'
import HomepageFooterCtaBgWebp from 'src/assets/images/HomepageFooterCtaBg.webp'
import { ROUTES } from 'src/config/routes'
import { isWebpSupported } from 'src/utils/isWebpSupported'
import { navigate } from 'gatsby'

export const ArBrokerCustomerLove: React.FC<TArBrokerCustomerLoveProps> = () => {
  const [footerCtaBgUrl, setFooterCtaBgUrl] = useState<string>()

  useEffect(() => {
    setFooterCtaBgUrl(isWebpSupported() ? HomepageFooterCtaBgWebp : HomepageFooterCtaBg)
  }, [])
  return (
    <>
      <ArContainer className={s.container} full>
        <h6>What our clients say</h6>
        <div className={s.quotation}>
          <h3>
            <span>
              <div>“</div>
            </span>
            Contract Builder forms a key element of the digital infrastructure Lockton in the UK is building to support
            its clients and deliver a digital service platform for the future.
            <span>
              <div>”</div>
            </span>
          </h3>
          <div>
            <img src={LocktonLogo} alt="Lockton logo" className="cover w-2/3" />
          </div>
          <p className={s.footer}>
            <span>Carl Smith</span> Head of Europe Operations and Transformation at Lockton
          </p>
        </div>
      </ArContainer>
      <ArRichCtaWithBg
        backgroundUrl={footerCtaBgUrl}
        heights={{
          mobile: METRICS.FooterHeightMobile,
          desktop: METRICS.FooterHeightMobile,
        }}
        title={[`Want to learn more?`]}
        subtitle="Book a chat with us to discover how we could transform your insurance business."
        actionButtons={[
          {
            title: 'Contact us',
            onClick: () => {
              navigate(ROUTES.contact())
            },
            theme: 'dark',
          },
        ]}
        withDarkGradient
      />
    </>
  )
}
