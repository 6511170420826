import React, { useContext } from 'react'

import { AppContext } from 'src/contexts/AppContext'

import { ArSeo } from 'src/components/ArSeo'
import { ROUTES } from 'src/config/routes'

import { ArBrokerHero } from 'src/components/ArBrokerHero'
import { ArBrokerFeatures } from 'src/components/ARBrokerFeatures'

const BrokerTools = () => {
  return (
    <>
      <ArSeo route={ROUTES.brokers()} />
      <ArBrokerHero />
      <ArBrokerFeatures />
    </>
  )
}

export default BrokerTools
